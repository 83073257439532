<template>
  <div>
    <b-card class="mt-0 mb-1">
      <b-row
        class="mb-1 pb-1"
      >
        <b-col cols="6">
          <div>
            <feather-icon
              icon="ListIcon"
              size="20"
              class="text-primary"
            /> <span
              class="text-primary"
              style="font-size:17px"
            ><strong>REGISTRAR NUEVA COMPRA</strong></span>
          </div>
        </b-col>
      </b-row>
      <b-card class="border mb-1">
        <b-row class="mb-1">
          <div>
            <feather-icon
              icon="ClipboardIcon"
              size="15"
              class="text-success ml-1"
            /> <span
              class="text-success"
              style="font-size:13px"
            ><strong>Detalles nuevo ingreso</strong></span>
          </div>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4">
            <label>Código compra</label>
            <b-form-input
              v-model="codigoCompra"
              type="text"
            />
          </b-col>
          <b-col cols="4">
            <label>Fecha compra</label>
            <flat-pickr
              v-model="fechaCompra"
              class="form-control"
              placeholder="YYYY-MM-DD"
            />
          </b-col>
          <b-col cols="4">
            <label>Fecha entrega</label>
            <flat-pickr
              v-model="fechaEntrega"
              class="form-control"
              placeholder="YYYY-MM-DD"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-0 border">
        <b-row class="mb-1">
          <div>
            <feather-icon
              icon="PlusSquareIcon"
              size="15"
              class="text-success ml-1"
            /> <span
              class="text-success"
              style="font-size:13px"
            ><strong>Adicionar producto/medicamento</strong></span>
          </div>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="5">
            <label for="">Buscar Medicamento</label>
            <vue-autosuggest
              ref="autocomplete2"
              v-model="query"
              :suggestions="suggestions"
              :input-props="inputProps"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="getSuggestionValue"
              @input="fetchResults"
            />
          </b-col>
          <b-col cols="1">
            <label for="">Cant.</label>
            <b-form-input
              v-model="cantidad"
              type="number"
            />
          </b-col>
          <b-col cols="2">
            <label for="">Precio c/u</label>
            <b-form-input
              v-model="precioCompra"
              type="text"
            />
          </b-col>
          <b-col cols="2">
            <label for="">Fecha vencimiento</label>
            <flat-pickr
              v-model="fechaVencimiento"
              class="form-control"
              placeholder="YYYY-MM-DD"
            />
          </b-col>
          <b-col cols="2">
            <b-button
              variant="outline-success"
              class="btn-icon mt-2 btn-block"
              @click="agregarLote"
            > agregar
              <feather-icon
                icon="PlusIcon"
              />

            </b-button></b-col>
        </b-row>

      </b-card>
    </b-card>

    <b-card class="mt-0 px-2">
      <b-row>
        <b-col md="12">
          <div class="d-flex mb-1">
            <feather-icon
              icon="ListIcon"
              size="15"
              class="text-success "
            />
            <span
              class="text-success"
              style="font-size:13px"
            ><strong>Lista de Productos</strong></span>
          </div>
        </b-col>
      </b-row>
      <b-table
        id="my-table"
        ref="refTablaIngresosCompra"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        responsive
        :busy.sync="isBusy"
        :items="productos"
        :fields="fields"
        sticky-header="350px"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>

        <template #cell(cantidad_compra)="row">
          <div class="d-flex">
            <b-form-input
              v-model="arrayCantidad[row.item.producto_id]"
              type="number"
              min="0"
              :max="row.item.stock"
              style="width: 70px"
              @input="actualizarCantidad(row.index,row.item.producto_id)"
            />

          </div>
        </template>

        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="eliminarProducto(row.index)"
          >
            quitar
          </b-button>

        </template>
      </b-table>

      <b-row>
        <b-col cols="12">
          <b-button
            class="mt-2 btn-block d-flex justify-content-center align-items-center"
            variant="outline-success"
            @click="guardarCompra"
          >
            <feather-icon
              icon="SaveIcon"
              size="20"
              class="text-success mr-1"
            /> Registrar compra
          </b-button>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BButton, BFormInput, VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,

    // vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,

    flatPickr,
    VueAutosuggest,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      arrayCantidad: [],
      // variables de add compra
      codigoCompra: '',
      proveedor: '',
      fechaCompra: '',
      fechaEntrega: '',
      productos: [],

      // variables de add producto
      fechaVencimiento: '',
      precioCompra: '',
      cantidad: '',
      query: '',
      itemSeleccionado: '',

      isBusy: false,
      // suggest
      timeout: null,
      ticos: [],
      debounceMilliseconds: 500,
      inputProps: {
        // id: 'autosuggest__input_ajax_',
        placeholder: 'Buscar por nombre',
        class: 'form-control',
        // name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        destinations: {
          limit: 50,
          label: '',
          onSelected: selected => {
            // console.log('item seleccionado:', selected)
            this.query = `${selected.item.nombre_corto} (${selected.item.stock})`
            this.itemSeleccionado = selected.item
            // this.ticos.push(selected.item)
          },
        },
      },

    }
  },
  methods: {
    // suggestions
    renderSuggestion(suggestion) {
      return (
          <div>
            <div class="text-primary font-weight-bold">
              <strong class="mr-1">{suggestion.item.nombre_corto}</strong>
              (<span>{suggestion.item.stock}</span>)
            </div>
            <div>
              <span>COD: {suggestion.item.cod_prod} - {suggestion.item.presentacion.nombre}</span>
            </div>
          </div>
      )
    },
    // eslint-disable-next-line no-unused-vars
    getSuggestionValue(suggestion) {
      /* const { codigo, item } = suggestion
      return codigo === 'hotels' ? item.title : `${item.codigo} ${item.descripcion}` */
    },
    fetchResults() {
      // console.log('entre')
      const { query } = this
      if (query.length < 3) {
        return
      }

      const perPage = 50
      // const servicioId = 2
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const dPromise = this.$http.get('/servicio-salud/farmacia/producto', { params: { filter: query, perPage } })
        dPromise.then(response => {
          // console.log('response: ', response)
          this.suggestions = []
          this.selected = null
          const { data } = response.data
          // eslint-disable-next-line no-unused-expressions
          data.length && this.suggestions.push({ name: 'destinations', data })
        })
      }, this.debounceMilliseconds)
    },
    agregarLote() {
      if (this.cantidad === '' || this.query === '' || this.fechaVencimiento === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'Tiene campos vacios',
            variant: 'info',
          },
        })
        return
      }
      if (this.cantidad === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Advertencia!',
            icon: 'BellIcon',
            text: 'La cantidad no puede ser igual a cero',
            variant: 'warning',
          },
        })
        return
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Agregado a la lista!',
          icon: 'CheckIcon',
          text: '',
          variant: 'success',
        },
      })
      this.productos.push(
        {
          producto_id: this.itemSeleccionado.id,
          medicamento: this.itemSeleccionado.nombre_corto,
          cantidad_compra: this.cantidad,
          fecha_vencimiento: this.fechaVencimiento,
          codigo: 'codigo x',
          precio_compra: this.precioCompra,
        },
      )
      this.arrayCantidad[this.itemSeleccionado.id] = this.cantidad
      // console.log('PRODUCTOS AGREGAR: ', this.productos)
    },
    guardarCompra() {
      // eslint-disable-next-line eqeqeq
      if (this.productos.length == 0 || this.codigoCompra == '' || this.fechaCompra == '' || this.fechaEntrega == '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Lista vacia!',
            icon: 'BellIcon',
            text: 'No se puede registrar la compra, ¡campos vacios!',
            variant: 'danger',
          },
        })
        return
      }
      // preguntamos si al gun producto tiene cero
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.productos.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (this.productos[index].cantidad_compra == 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '¡Advertencia!',
              icon: 'BellIcon',
              text: 'Algún producto en la lista tiene cero en cantidad',
              variant: 'warning',
            },
          })
          return
        }
      }

      const data = {
        codigo: this.codigoCompra,
        proveedor_id: 1,
        fecha_compra: this.fechaCompra,
        fecha_entrega: this.fechaEntrega,
        productos: this.productos,
      }

      this.$http.post('/servicio-salud/farmacia/compra', data).then(() => {
        // console.log('respuestaPost: ', res)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Éxito!',
            icon: 'BellIcon',
            text: 'Compra registrado correctamente',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'farmacia.input.list' })
      }).catch(res => {
        console.log(res)
      })
    },
    actualizarCantidad(index, idProducto) {
      if (this.arrayCantidad[idProducto] === '') {
        this.productos[index].cantidad_compra = 0
      } else {
        this.productos[index].cantidad_compra = this.arrayCantidad[idProducto]
      }
      // console.log('PRODUCTOS: ', this.productos)
    },
    eliminarProducto(index) {
      this.productos.splice(index, 1)
    },
  },
  setup() {
    const fields = [
      { key: 'producto_id', label: 'ID', sortable: true },
      { key: 'medicamento', label: 'MEDICAMENTO', sortable: true },
      { key: 'cantidad_compra', label: 'CANTIDAD', sortable: true },
      { key: 'fecha_vencimiento', label: 'FECHA VENCIMIENTO', sortable: true },
      { key: 'acciones', label: 'ACCIONES', sortable: true },
    ]

    return {
      // Filter
      avatarText,
      fields,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
